import Honeybadger from 'honeybadger-js';
window.Honeybadger = Honeybadger;
import Environment from 'application_environment';

const env = Environment();

Honeybadger.configure({
  apiKey: '207ab99c',
  async: true,
  revision: env.revision,
  ssl: true,
  onerror: true,
  environment: env.rails_env,
  disabled: env.rails_env === 'test' || env.rails_env === 'development'
});

if ( env.current_user_id ) {
  Honeybadger.setContext({
    user_id: env.current_user_id,
    user_email: env.current_user_email,
    strategist_id: env.current_strategist_id,
    strategist_email: env.current_strategist_email
  });
}
